<template>
  <b-card>

    <h4 v-if="currentObject.event_name" class="mb-2">
      Client Access <a :href="'/events/' + currentObject.event_id + '/view'">({{ currentObject.event_name }})</a>
    </h4>
    
    <b-button size="md" variant="primary" @click="add()" v-if="$can('add', 'event_managers')">
      <feather-icon
        icon="PlusIcon"
        class="mr-50"
      /><span>Add</span>
    </b-button>
    
    <!-- search input -->
    <div class="custom-search float-right">
      <b-form-group>
        <div class="d-flex align-items-center">
          <label class="mr-1">Search</label>
          <b-form-input
            v-model="searchTerm"
            placeholder="Search"
            name="text"
            class="d-inline-block"
          />
        </div>
      </b-form-group>
    </div>

    <!-- table -->
    <div v-if="!rows || isLoadingTable || !currentObject.event_name" class="text-center">
        <b-spinner label="Loading..."></b-spinner>
    </div>
    <vue-good-table
      v-if="rows && !isLoadingTable"
      :columns="columns"
      :rows="rows"
      :search-options="{
        enabled: true,
        externalQuery: searchTerm }"
      :pagination-options="{
        enabled: true,
        perPage:pageLength
      }"
    >
      <template
        slot="table-row"
        slot-scope="props"
      >

        <!-- Column: Row number -->
        <span
          v-if="props.column.field === 'row_number'"
          class="text-nowrap"
        >
          <span class="text-nowrap">{{ (props.row.originalIndex + 1) }}</span>
        </span>

        <!-- Column: Name -->
        <span
          v-if="props.column.field === 'name'"
          class="text-nowrap"
        > 
          <a href="#" @click="goUser(props.row.user.id)">{{ (props.row.user) ? props.row.user.name : '-' }}</a>
        </span>

        <!-- Column: Email -->
        <span
          v-if="props.column.field === 'email'"
          class="text-nowrap"
        > {{ (props.row.user) ? props.row.user.email : '-' }}
        </span>

        <!-- Column: Created by -->
        <span
          v-if="props.column.field === 'updated_by_user'"
          class="text-nowrap"
        >
          <span class="text-nowrap">{{ (props.row.updated_by) ? props.row.updated_by.name : props.row.created_by.name }}</span>
        </span>

        <!-- Column: Action -->
        <span v-else-if="props.column.field === 'action'">
          <span>
            <b-dropdown
              variant="link"
              toggle-class="text-decoration-none"
              no-caret
            >
              <template v-slot:button-content>
                <feather-icon
                  icon="MoreVerticalIcon"
                  size="16"
                  class="text-body align-middle mr-25"
                />
              </template>
              <b-dropdown-item @click="removeModal(props.row.user_id)" v-if="$can('delete', 'event_managers')">
                <feather-icon
                  icon="TrashIcon"
                  class="mr-50"
                />
                <span>Delete</span>
              </b-dropdown-item>
            </b-dropdown>
          </span>
        </span>

        <!-- Column: Common -->
        <span v-else>
          {{ props.formattedRow[props.column.field] }}
        </span>
      </template>

      <!-- pagination -->
      <template
        slot="pagination-bottom"
        slot-scope="props"
      >
        <div class="d-flex justify-content-between flex-wrap">
          <div class="d-flex align-items-center mb-0 mt-1">
            <span class="text-nowrap ">
              Showing 1 to
            </span>
            <b-form-select
              v-model="pageLength"
              :options="['3','5','10']"
              class="mx-1"
              @input="(value)=>props.perPageChanged({currentPerPage:value})"
            />
            <span class="text-nowrap"> of {{ props.total }} entries </span>
          </div>
          <div>
            <b-pagination
              :value="1"
              :total-rows="props.total"
              :per-page="pageLength"
              first-number
              last-number
              align="right"
              prev-class="prev-item"
              next-class="next-item"
              class="mt-1 mb-0"
              @input="(value)=>props.pageChanged({currentPage:value})"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </div>
        </div>
      </template>
    </vue-good-table>

    <div class="mt-2">
      <div style="float: left">
        <b-button size="md" variant="outline-primary" @click="viewAllEvents()">
          <feather-icon
            icon="ArrowLeftIcon"
            class="mr-50"
          /><span>Back</span>
        </b-button>
      </div>
    </div>

  <!-- Modals -->
    <b-modal
      id="modal-form"
      cancel-variant="outline-secondary"
      cancel-title="Close"
      centered
      v-model="showFormModal"
    >
      <template #modal-header="{}">
        <h5>Add {{ pageName }}</h5>
      </template>

      <div v-if="isLoadingForm" class="text-center">
          <b-spinner label="Loading..."></b-spinner>
      </div>

      <b-form v-if="!isLoadingForm" @submit.prevent="save()">
        <b-form-group>
          <label for="user">User:</label>
            <v-select
              v-model="currentObject.user_id"
              :options="users"
              :reduce="val => val.value"
              :clearable="false"
              input-id="user"
              placeholder="Pick a user"
              v-if="users !== null"
            />
        </b-form-group>
      </b-form>

      <template #modal-footer="{ cancel }">
        <b-button size="md" variant="outline-secondary" @click="cancel()">
          Cancel
        </b-button>
        <b-button size="md" variant="primary" @click="save()" :disabled="!isValid() || isLoadingForm">
          {{ (!isLoadingForm) ? "Save" : "Loading..." }}
        </b-button>
      </template>


    </b-modal>

  <!-- Modals -->
    <b-modal
      id="modal-delete"
      cancel-variant="outline-secondary"
      cancel-title="Close"
      centered
      v-model="showDeleteModal"
    >
      <template #modal-header="{}">
        <h5>Delete {{ pageName }}</h5>
      </template>

      <p>
        Are you sure you want to delete this item?
      </p>

      <template #modal-footer="{ cancel }">
        <b-button size="md" variant="outline-secondary" @click="cancel()">
          Cancel
        </b-button>
        <b-button size="md" variant="danger" @click="remove()" :disabled="isLoadingDelete">
          {{ (isLoadingDelete) ? "Loading..." : "Delete" }}
        </b-button>
      </template>


    </b-modal>
  </b-card>

</template>

<script>
import {
  BPagination, BFormGroup, BFormInput, BFormSelect, BDropdown, BDropdownItem, BCard, BForm, BButton, BSpinner, BRow, BCol
} from 'bootstrap-vue'
import router from '@/router'
import { VueGoodTable } from 'vue-good-table'
import vSelect from 'vue-select'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    VueGoodTable,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    BCard,
    BForm,
    BButton,
    BSpinner,
    vSelect,
    BRow,
    BCol
  },
  data() {
    return {
      users: null,
      pageLength: 10,
      dir: false,
      columns: [
        {
            label: 'No',
            field: 'row_number'
        },
        {
          label: 'Name',
          field: 'name',
        },
        {
          label: 'Email',
          field: 'email'
        },
        {
          label: 'Last Updated By',
          field: 'updated_by_user',
        },
        {
          label: 'Action',
          field: 'action',
        },
      ],
      rows: [],
      searchTerm: '',

      isLoadingTable: false,
      isLoadingForm: false,
      isLoadingDelete: false,
      showDeleteModal: false,
      showFormModal: false,
      pageName: "Client Access",

      currentObject: {
        user_id: -1,
        event_id: -1,
        event_name: null
      },

      currentUserObject: null
    }
  },
  computed: {
  },
  methods: {
    viewAllEvents() {
      this.$router.push('/events')
    },
    save() {
      this.$http.post('/admin/v1/events/' + this.currentObject.event_id + '/managers', {
        user_id: this.currentObject.user_id,
      })
        .then(res => {
          const data = res.data
          const message = data.message

          if(!data.status) {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: `Error`,
                icon: 'CoffeeIcon',
                variant: 'danger',
                text: message,
              },
            })
          }
          else {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: `Success`,
                icon: 'CoffeeIcon',
                variant: 'success',
                text: message,
              },
            })

          }
          
          this.showFormModal = false

          this.isLoadingForm = false

          this.fetchData()
      })
    },
    goUser(user_id) {
      this.$router.push('/users/' + user_id + '/view')

    },
    add() {
      this.fetchUsers()

      this.showFormModal = true

      this.currentObject.user_id = null
      this.currentObject.event_id = router.currentRoute.params.id
    },
    removeModal(user_id) {      
      this.showDeleteModal = true

      this.currentObject.user_id = user_id
      this.currentObject.event_id = router.currentRoute.params.id

    },
    remove() {
      this.isLoadingDelete = true

      this.$http.post('/admin/v1/event_managers/remove', {
        user_id: this.currentObject.user_id,
        event_id: this.currentObject.event_id
      })
        .then(res => {
          const data = res.data
          const message = data.message

          if(!data.status) {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: `Error`,
                icon: 'CoffeeIcon',
                variant: 'danger',
                text: message,
              },
            })
          }
          else {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: `Success`,
                icon: 'CoffeeIcon',
                variant: 'success',
                text: message,
              },
            })

          }
          
          this.showDeleteModal = false

          this.isLoadingDelete = false

          this.fetchData()
      })
    },
    fetchEvent() {
      const id = router.currentRoute.params.id

      this.$http.get('/admin/v1/events/' + id)
        .then(res => {
          const data = res.data
          const message = data.message

          if(!data.status) {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: `Error`,
                icon: 'CoffeeIcon',
                variant: 'danger',
                text: message,
              },
            })
          }
          else {
            const currentData = res.data.data

            this.currentObject.event_name =  currentData.name
          }
     })
    },
    fetchData() {
      this.isLoadingTable = true

      const id = router.currentRoute.params.id

      this.currentObject.event_id = id

      this.$http.get('/admin/v1/events/' + this.currentObject.event_id + '/managers')
        .then(res => {
          const data = res.data
          const message = data.message

          if(!data.status) {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: `Error`,
                icon: 'CoffeeIcon',
                variant: 'danger',
                text: message,
              },
            })
          }
          else {
            const currentData = res.data.data
          
            this.rows = currentData
          }

          this.isLoadingTable = false

     })
    },
    fetchUsers() {
      this.isLoadingForm = true

      this.$http.get('/admin/v1/event_managers_verified')
        .then(res => {         
          const data = res.data
          const message = data.message

          if(!data.status) {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: `Error`,
                icon: 'CoffeeIcon',
                variant: 'danger',
                text: message,
              },
            })
          }
          else {
            this.users = []

            const currentData = res.data.data

            currentData.forEach((value, index) => {
              this.users.push({
                label: value.email + " (" + value.name + ")",
                value: value.id
              })
            })
          }

          this.isLoadingForm = false

     })
    },
    isValid() {
      return this.currentObject.user_id !== null && this.currentObject.user_id != ''
    }
  },
  created() {
    this.fetchData()
    this.fetchEvent()
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
